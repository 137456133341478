@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.product-detail-container {
    display: flex;
    margin-top: 100px;
}

.product-detail-container .product-images-left {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 40%;
}

.product-detail-container .product-images-left .large-image-product {
    margin-bottom: 10px;
    background-color: #E6E8EC;
    padding: 30px 25px ;
    object-fit: cover;

}


.product-detail-container .product-images-left .small-images-product {
    display: flex;
    justify-content: space-between;
    
}

.product-detail-container .product-images-left .small-images-product img {
    width:30%;
    background-color: #E6E8EC;
    padding: 10px 10px;
}
.product-detail-container .product-images-left .small-images-product img:nth-child(2){
    margin: 0 10px;
}

.product-detail-container .product-info-center {
    padding: 0 1rem;
    width: 40%;
}


.product-detail-container .product-details-container {
    display: flex;
    flex-direction: column;
    
}


/* Computer & Hardware */
.product-detail-container .product-info-center .product-details-container .product-header-title .product-category{
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* Neve Strix Pro L123 (2021) - TP399K 1TB */
.product-detail-container .product-info-center .product-details-container .product-header-title .product-title {
    color: #212121;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    /* margin-top: 15px; */
}

/* $415.10 */
.product-detail-container .product-info-center .product-details-container .product-header-title .product-price {
    color:  #212121;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

/*We provide .. in case there are any issues with our products. */
.product-detail-container .product-info-center .product-details-container .product-header-title .txt{
    color:#828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    margin-bottom:15px;
}

/* one-year warranty */
.product-detail-container .product-info-center .product-details-container .product-header-title .mid-sentence{
    color: #212121;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.product-detail-container .product-info-center .product-details-container .product-header-title .product-rating {
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.product-detail-container .product-info-center .product-details-container .product-header-title  span {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.product-detail-container  .product-details-container .product-colors {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #E2E2E2;
    padding: 0 0 15px 0;
}

.product-detail-container .product-details-container .product-colors .colors-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
    padding: 15px 0 15px 0;
}
.product-detail-container .product-details-container .product-colors .memory-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E2E2E2;
    padding: 0 0 15px 0;
}

.product-detail-container .product-details-container .product-colors .color-option h2 {
    margin-right: 1rem;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.product-detail-container .product-details-container .product-colors .quantity-cont {
    display: flex;
    align-items: center;
    width: 55%;
    justify-content: space-between;
    border-radius: 1rem;
}

.product-detail-container .product-details-container .product-colors .quantity-cont .quantity {
    display: flex;
    align-items: center;
}

.product-detail-container .product-details-container .product-colors .quantity-cont .quantity .plus,
.product-detail-container .product-details-container .product-colors .quantity-cont .quantity .minus {
    text-align: center;
    cursor: pointer;
    background-color: #E6E8EC;
    padding: 0 5px;
}

.product-detail-container .product-details-container .product-colors .quantity-cont .quantity .num {
    margin: 0 10px;
}

.cards {
    display: flex;
    align-self: end;
}

.memory-size {
    /* width: 55px; */
    padding: 5px 10px;
    border: 1px solid #ccc;
    text-align: center;
    margin: 5px;
    align-items: center;
}

.memory-size:hover,
.color-card:hover {
    border: 1px solid #8139A1;
}

.color-card {
    /* width: 55px; */
    padding: 5px 10px;
    border: 1px solid #ccc;
    text-align: center;
    margin: 5px;
}

.product-actions {
    display: flex;
    gap: 1rem;
}

.product-actions .buy-btn,
.product-actions .add-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    margin-top: 5px;
    border-radius: 4px;
}

.product-actions .buy-btn {
    background: #8139A1;
    color: white;
}

.product-actions .add-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #B0B5B9;
    color: black;
}

.submit-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border-radius: 4px;
    background: #8139A1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: white;
}
/* End the center div */



/* Start the right div */
.product-detail-container .product-options-right {
    padding: 0 .1rem;
    width: 25%;
}


.delivery-options,
.customization {
    margin-bottom: 1rem;
    border: 1px solid #E2E2E2;
    padding: 20px 15px;
}
/* Choose Delivery package */
.delivery-options .delivery-options-title {
    color: #212121;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}

.delivery-items-cont{
    margin-top:15px;
}

/* Delivery Package */
.delivery-options .package-title {
    color: #212121;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
/* 7 day delivery test */
.delivery-options .delivery-time {
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.delivery-item{
    margin-bottom: 15px;
    padding: 5px 0;

}
/* 2$ */
.delivery-options .delivery-item .dollars .dollars-num {
    color: #52057B;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.delivery-options .delivery-item .inputs-group {
    display: flex;
    justify-content: space-between;
    /* gap: 50px; */
    align-items: center;
}

.delivery-options .inputs-group .package {
    display: flex;
    align-items: center;
    gap:8px;
}




.customization .customization-title {
    color: #212121;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; 
}

.customization span {
    color: #007A0C;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: block;
    margin-bottom: 0.5rem;
    padding: 5px ;
}

.customization input{
    padding: 5px ;
}
.customization .btn {
    display: block;
}

.customization input {
    /* width: 100%; */
    /* padding: 0.5rem; */
    border: 1px solid #ccc;
    border-radius: 5px;
}

.customization .submit-btn {
    width: 100%;
    /* padding: 0.5rem; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}




@media (max-width: 992px) {
    .product-detail-container {
        flex-direction: column;
    }

    .product-detail-container .product-images .large-image img,
    .product-detail-container .product-images-left .small-images img {
        width: 100%;
    }

    .product-detail-container .product-options-right {
        width: 100%;
    }
    .product-detail-container .product-images-left {
        width: 100%;
    }
    .product-detail-container .product-info-center {
        width: 100%;
        margin: 50px 0;
    }
}




@media (max-width: 768px) {
    .product-detail-container {
        flex-direction: column;
    }

    .product-detail-container .product-images .large-image img,
    .product-detail-container .product-images-left .small-images img {
        width: 100%;
    }

    .product-detail-container .product-options-right {
        width: 100%;
    }

    .product-detail-container .product-images-left {
        width: 100%;
    }

    .product-detail-container .product-info-center {
        width: 100%;
        margin: 50px 0;
        padding: 0;
    }

    .product-detail-container .product-options-right {
        width: 100%;
        margin: 0;
    }
}


@media (max-width: 480px) {
    .product-detail-container .product-options-right {
        width: 100%;
    }
    .product-detail-container .product-images .large-image img,
    .product-detail-container .product-images-left .small-images  {
        width: 90%;
    }

    /* .small-images-product{
        width: 80%;
    } */
}