.main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.main-container .top-div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid #d2ced4;


}

.main-container .top-div .tab {
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
    text-align: center;
    flex-grow: 1;

}

.main-container .top-div .tab h2 {
    margin: 0;
}

.main-container .top-div .tab.active {
    border-bottom: 2px solid #8139A1;
}

.component-container {
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
}