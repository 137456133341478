

.left-card-payment {
    width: 100%;
    padding: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    /* margin-top: 100px; */
    margin: 30px auto;

}

.left-section1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.left-section1 span {
    cursor: pointer;
    transition: color 0.3s;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 22px;
}

.left-section1 .progress-line {
    height: 2px;
    background: gray;
    margin: 10px 0;
    transition: background-color 0.3s;
}

.left-section1 .progress-line.active {
    background: green;
    text-decoration: underline;
}

.active-check-icon {
    color: gray;
    transition: color 0.3s;
}

.active-check-icon.active {
    color: green;
}

.customer-information-section, .payment-details-section {
    width: 100%;
}

.left-section2 .top-section {
    margin-bottom: 20px;
}

.left-section2 .top-section .title-top .title,
.left-section3  .top-section .title,
.left-section2 .top-section .text,
.left-section3 .text {
    display: block;
    color: #212121;
    font-size: 20px;
    font-weight: 500;
}

.left-section2 .top-section .title-top .title.active{
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: green;
    text-decoration-thickness: 3px;
}

.left-section2 .top-section .text,
.left-section3 .text {
    color: #828282;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
}

.left-section2 .bottom-section .inputs-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.input-wrapper {
    position: relative;
    flex: 1;
    margin-right: 10px;
}

.input-wrapper:last-child {
    margin-right: 0;
}

.input-wrapper .input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #828282;
}

.input-wrapper input {
    width: 100%;
    padding: 10px 10px 10px 35px;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    background: #FFF;
    outline: none;
}

.input-wrapper .input-title{
        margin-bottom:10px;

}

.input-wrapper input::placeholder {
    padding-left: 5px;
}

textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #E2E2E2;
    border-radius: 8px;
    background: #FFF;
    resize: none;
    margin-bottom: 20px;
}

.left-section3 .payment-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #8139A1;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.checkout-button {
    width: 100%;
    padding: 15px;
    background: #8139A1;
    color: #FFF;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s;
}


.form-input:focus,
.form-textarea:focus {
    border-color: #6e2c8e;
    outline: none; 
}

.continue-btn{
    border: 1px solid #6e2c8e;
    color: #6e2c8e;
    background-color: #fff;
}

@media (max-width: 768px) {
    .left-card-payment {
        width: 100%;
    }

    .left-section2 .bottom-section .inputs-row {
        flex-direction: column;
    }

    .input-wrapper {
        margin-right: 0;
        margin-bottom: 15px;
    }

    .input-wrapper:last-child {
        margin-bottom: 0;
    }
}


.left-section1 .progress-line.active {
    background: #6e2c8e;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: #6e2c8e;
    text-decoration-thickness: 3px;
}

.input-wrapper.full-width {
    width: 100%;
}

/* .select-country {
    width: 100%;
} */

.checkout-button {
    margin-top: 20px;
}

/* Style for the select dropdown */

.country-select {
    width: 100%;
    border: 1px solid #6e2c8e;
    border-radius: 4px;
    margin-bottom: 12px;
    appearance: none; 
    color: #6e2c8e;
    padding-right: 40px; /* Space for the custom arrow */

    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 10px;
    margin-right: 2rem;
    padding: 10px;
}

/* Style when a country is selected */
.country-select.selected {
    border-color: #6e2c8e;
}

/* Remove focus outline */
.country-select:focus {
    outline: none;
    border-color: #6e2c8e;
}

/* Change the arrow color and spacing */
.country-select option {
    color: #6e2c8e; 
}


.three-cards {
    display: flex;
    margin: 10px 0;
    width: 100%;
    justify-content:space-between;
    gap: 20px; 
}

.card-info {
    position: relative;
    width:calc(100%/3);
}

.card-info span {
    position: absolute;
    top: 0;
    left: 50px;
    transform: translate(-50%);
    background: white; 
    padding: 2px 5px;
}

.card-info input {
    width: 100%;
    padding: 20px; 
    /* box-sizing:border-box; */
    border: 1px solid #E2E2E2;

}




/* Mobile adjustments */
@media (max-width: 768px) {
    .left-section2 .bottom-section .inputs-row {
        flex-direction: column;
    }

    .input-wrapper {
        margin-bottom: 15px;
    }

    .input-wrapper.full-width {
        width: 100%;
    }
    .three-cards {
        flex-direction: column;
    }
    .card-info{
        width:100%;
    }
}