
.right-card{
    width: 100%;
    padding: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    /* height: 100vh;  */
}

.right-card .right-section2 .right-div {
    display: flex;
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    background: #FFF;
    padding: 20px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}


.right-card .right-card-info .right-section-bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}
.right-card .right-card-info .right-section-bottom .sub-title{
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.right-section-btn button {
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background: #8139A1;
    color: #FFF;
    text-align: center;
    font-family: Rubik;
    font-size: 20px;
    font-weight: 600;
    line-height: 150%;
    width: 100%;
    border: none;
    cursor: pointer;
}

.right-section2 .left-div img {
    width: 70px;
}
/* right card */
.right-card .right-section1 .sub-title {
    color: #212121;
    font-size: 20px;
    font-weight: 600;
    line-height: 150%;
}
.right-card .right-section1 p {
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.right-section2 .right-div .left-div img{
    background-color: #E6E8EC;
    padding: 10px;
    border-radius: 4px;
}

.right-section2 .right-div .item-info .top-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.right-section2 .right-div .item-info .item-title {
    width: 75%;
    
}
.right-section2 .right-div .item-info .item-title .item-name {
    color: #212121;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; 
}
.right-section2 .right-div .item-info .item-price {
    color: #52057B;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.right-div .top-row,
.right-div .bottom-row {
    display: flex;
    justify-content: space-between;
}