@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.shop-card-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  margin-top: 100px;
  font-family: 'Rubik', sans-serif;
}

.shop-card-container .top-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.shop-card-container .top-div .shop-card-title {
  margin: 0;
  color: #161D25;
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0.2px;
  text-align: left;
}

.bottom-div .card {
  background: #fff;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
  border: none;
}

.bottom-div .card .image-container {
  background-color: #f0f0f0;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

.bottom-div .card .image-container .card-image {
  width: 50px;
  height: 50px;
}

.bottom-div .card .card-title {
  margin: 10px 0;
  color: #161D25;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px; 
}

.bottom-div .card .card-description {
  color: #959EAD;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; 
}

@media (max-width: 768px) { 
  .shop-card-container .top-div {
    flex-direction: column;
    align-items: center;
  }

  .shop-card-container .bottom-div {
    flex-direction: column;
  }

  .shop-card-container .top-div .shop-card-title {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 480px) {
  .shop-card-container .top-div .shop-card-title {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 390px) {
  .bottom-div .card .card-title {
    font-size: 14px;
  }
}
