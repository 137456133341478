.sidebar-container h2{
  color: #212121;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}
.sidebar-title {
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 10px;
}

.sidebar-items {
  margin-top: 20px;
}

.sidebar-label-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sidebar-label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  /* padding: 10px; */
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.all {
  background: linear-gradient(blue, crimson);
}

.sidebar-label-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.sidebar-label-container input:checked ~ .checkmark {
  /* background-color: #8139A1; */
  color: #8139A1;
  border: #8139A1;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.sidebar-label-container input:checked ~ .checkmark:after {
  display: block;
}

.sidebar-label-container .checkmark:after {
  top: 6.4px;
  left: 6.4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}

.line {
  margin-top: 3rem;
  border-color: #f7f7f7;
}
