/* .policy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
    margin-top: 120px;
    margin-bottom: 70px;
  }
  
  .policy-container h1 {
    text-align: center;
    color: #007acc;
    margin-bottom: 20px;
  }
  
  .policy-container section {
    margin-bottom: 20px;
  }
  
  .policy-container h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #444;
  }
  
  .policy-container p {
    font-size: 1em;
    line-height: 1.6;
    color: #666;
  }
   */


.policy_header {
    background-image: url('../assets/header.png');
    -webkit-clip-path: ellipse(71% 59% at 50% 40%);
    clip-path: ellipse(71% 59% at 50% 40%);
    position: relative;
    color: white;
    text-align: center;
    width: 100%;
    height: 400px;
}

.policy_header .header_txt{
    position: absolute;
    top: 50%;
    left: 50%;
    transform:  translate(-50%, -50%);
    text-align: center;
    max-height: 40%;
}
.policy_header h1 {
    margin: 0;
    font-size: 2rem;
 
}
.policy_header p {
    margin: 5px 0 0;
    font-size: 0.9rem;
    opacity: 0.8;
}

/* Policy Content Section */
.content-container {
    max-width: 800px;
    margin: 20px auto;
    background: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #444;
    margin: 100px auto;
}
.section-title {
    color: #8139A1;
    font-weight: bold;
    margin-top: 20px;
}
.content-container p {
    margin: 10px 0;
}