.wrapper-content{
  margin-top: 100px;
  height: 60vh;
}
.desc h3 {
    font-size: 16px;
}

.ibox-title .pull-right,
.ibox-title h5{
  color: #8139A1;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
  cursor: pointer;
}
.text-navy {
    color: #1ab394;
}
.cart-product-imitation {
  text-align: center;
  padding-top: 30px;
  height: 80px;
  width: 80px;
  background-color: #f8f8f9;
}
.product-imitation.xl {
  padding: 120px 0;
}
.product-desc {
  padding: 20px;
  position: relative;
}
.ecommerce .tag-list {
  padding: 0;
}
.ecommerce .fa-star {
  color: #d1dade;
}
.ecommerce .fa-star.active {
  color: #f8ac59;
}
.ecommerce .note-editor {
  border: 1px solid #e7eaec;
}
table.shoping-cart-table {
  margin-bottom: 0;
}
table.shoping-cart-table tr td {
  border: none;
  text-align: right;
}
table.shoping-cart-table tr td.desc,
table.shoping-cart-table tr td:first-child {
  text-align: left;
}
table.shoping-cart-table tr td:last-child {
  width: 80px;
}
.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}
.ibox.collapsed .ibox-content {
  display: none;
}
.ibox:after,
.ibox:before {
  display: table;
}
.ibox-title {
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px;
}
.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}
.ibox-footer {
  color: inherit;
  border-top: 1px solid #e7eaec;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 15px;
}

.item__card {
  max-height: 400px;
  overflow-y: auto; 
  padding-right: 15px; 
}


.summary__card{
    border-radius: 10px;
  padding: 10px;
  margin: 0 auto;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.text-muted  {
  color: red !important; 
}

.checkout_container{
  margin-top: 20px;
}

.checkout_btn:hover{
  background-color: #8139A1;
  color: white;
}
.checkout_btn{
  padding: 5px 20px;
  border: 1px #8139A1 solid;
  color: #8139A1;
  border-radius: 25px;
}


@media (max-width: 768px) {
  .wrapper-content{
    height: 100vh;
  }
.wrapper-content .row{
  display: flex;
  flex-direction: column;
  margin-bottom: 120px !important;

}
tr{
  display: flex;
  flex-direction: column;
}

}