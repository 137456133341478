@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');


.navbar-brand {
  padding: 0 !important;
}

.nav-brand {
  display: inline-block;
}

.logoImg {
  width: 85%;
  height: 100%;
  object-fit: contain;
}


/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 400px;
  position: relative;
  text-align: center;
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Animation for Modal */
.animate-modal {
  animation: fadeIn 0.5s ease forwards;
}

/* Fade-In Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Input and Button */
.input-group {
  margin-bottom: 1rem;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.input-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-btn {
  background-color: #8139A1;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;

}

.login-btn:hover {
  background-color: #8139A1;
}

.main-toggle {
  margin-top: 15px;
}

.toggle-link {
  cursor: pointer;
  color: #8139A1;
}


.arabic {
  text-align: right;
  font-family: "Tajawal", sans-serif;
}

.english {
  text-align: left;
  font-family: "Poppins", sans-serif;
}


.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.nav-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  padding: 0 20px;
  margin: 0 auto;
  border: 1px solid rgb(204, 204, 204);
  /* border-image: initial;; */
}

.search-container {
  display: flex;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  padding: 5px;
}

.category-container {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.search-container .icon {
  font-size: 20px;
  margin: 0 5px;
}

.search-container input {
  border: none;
  outline: none;
  padding: 0 12px;
  flex-grow: 1;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: white;
  border: 2px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.dropdown-options {
  display: none;
  position: absolute;
  background-color: white;
  border: 2px solid #ccc;
  margin-top: 5px;
  padding: 0;
  list-style: none;
  width: 100%;
}

.custom-dropdown:hover .dropdown-options {
  display: block;
}

.dropdown-option {
  padding: 10px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #6a0dad;
  color: white;
}


.nav-icons,
.person-icon {
  font-size: 1.8rem;
  margin-left: 20px;
}

.login-register {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.login-register .register {
  margin: 0 10px;
  text-decoration: none;
  font-weight: 600;
  color: #8139A1;
  cursor: pointer;
}

.login-register .login {
  margin: 0 10px;
  text-decoration: none;
  font-weight: 600;
  color: #8139A1;
  cursor: pointer;
}



.search-container,
.category-container {
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.search-container .icon {
  font-size: 20px;
  margin: 0 5px 0 5px;
}

.category-container .category-search-icon {
  background-color: black;
  padding: 5px;
  color: white;
  border-radius: 5px;
  margin: 0 5px 0 20px;
  font-size: 30px;
}


.category-container select {
  border: none;
  outline: none;
  padding: 10px;
}

.profile-container {
  display: flex;
  align-items: center;
  position: relative;
}

.nav-icons {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 10px;
  color: black;
  font-size: 2rem;
}

.login-register {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.login-register .person-icon {
  margin-right: 10px;
  font-size: 2rem;
}

.login-register-text a {
  margin: 0 5px;
  text-decoration: none;
}

.login-register-text .login {
  color: #8139A1;
}

.login-register-text .register {
  color: black;
}


.burger-icon {
  font-size: 2rem;
}

.responsive-icons {
  font-size: 2rem;
  margin: 0 5px;
}

.search-icon {
  margin: 0 10px;
  margin-right: 10px;
}


/* Dark mode specific styles */
.navbar-dark .nav-link {
  color: #ffffff !important;
}

.burger-icon-dark {
  border-color: #ffffff;
  color: #ffffff !important;
}



.offcanvas-dark {
  background-color: #000000;
}

.offcanvas-dark .nav-link {
  color: #ffffff !important;
}

.header-dark {
  background-color: #000000;
}


.light-icon-dark {
  color: #ffffff !important;
}

.dark-icon-dark {
  color: #ffffff !important;
}


.close-light {
  background-color: #000000;
}


.offcanvas .close-dark {
  background-color: #ffffff;
}


/* --------------------------------------- */


.navBar {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.show-menu {
  right: 0;
}

.language-toggle-btn {
  border: none;
  color: #044FB0;
  font-size: 18px;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease;
  margin-top: 15px;
  align-items: center;
}

.language-toggle-btn:hover {
  background-color: #f0f0f0;
}


.language-toggle-btn:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}


.nav-link:active {
  position: relative;
  padding: 8px 0;
  text-decoration: none;
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}


.nav-link {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.274px;
}


.active-link {
  color: #044FB0 !important;
  font-weight: 700;
}

.burger-icon {
  display: none;
}

.responsive-icons {
  display: none;
}


.offcanvas-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logout {
  color: #8139A1;
  cursor: pointer;
}


.profile-container {
  position: relative;
  display: inline-block;
}

/* .profile-name {
  position: absolute;
  bottom: 120%;
   to move the name above the icon 
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
  font-size: 14px;
  pointer-events: none;
} */

/* .profile-container:hover .profile-name {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(25px);
  Moves it slightly up
} */

.person-icon {
  font-size: 24px;
  cursor: pointer;
}



/* Sidebar Responsive Design */
@media screen and (max-width: 992px) {

  .burger-icon {
    display: block !important;
    font-size: 2rem;
  }

  .responsive-icons {
    display: flex;
  }

  .nav-center {
    display: none;
  }


  .offcanvas-header.arabic {
    flex-direction: row-reverse;
  }

  .offcanvas-header.english {
    flex-direction: row;
  }

  .offcanvas-header .btn-close {
    margin: 0;
  }

  .burger-icon-light {
    border-color: #000000;
  }

  .offcanvas {
    width: 50% !important;
    max-width: 100%;
  }

  .offcanvas-body {
    padding: 2rem 1rem;
  }

  .offcanvas .nav-link.arabic {
    text-align: right;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
    transition: color 0.3s ease-in-out;

  }

  .offcanvas .nav-link.english {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    transition: color 0.3s ease-in-out;
  }

  .offcanvas .nav-link:hover {
    color: #044FB0;
  }


  .offcanvas .language-toggle-btn {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    background-color: #044FB0;
    color: #fff;
    gap: 8px;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
  }


  .offcanvas .switcher-btn {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    gap: 8px;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
  }

  .offcanvas .logoImg {
    width: 60px;

  }
}


@media (max-width: 768px) {

  .nav-icons,
  .person-icon {
    font-size: 1.6rem;
    margin-left: 10px;
  }

  .d-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login-register {
    text-align: center;
    margin: 10px auto;
  }

  .login-register span,
  .nav-icons,
  .person-icon {
    display: none;
  }

  nav {
    left: 0;
    flex-direction: column;
  }

  .profile-container {
    justify-content: space-between;
    margin-top: 10px;
  }

  .login-register {
    flex-direction: column;
    align-items: flex-start;
  }

  .login-register-text a {
    margin: 5px 0;
  }

  .d-flex {
    display: block !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}



@media screen and (max-width: 576px) {
  .offcanvas {
    width: 60% !important;
  }
}


@media (max-width: 480px) {
  nav {
    padding: 10px;
  }

  .nav-icons {
    margin: 0 5px;
  }

  .login-register-text {
    flex-direction: column;
  }

  .login-register-text a {
    margin: 5px 0;
  }
}


@media screen and (max-width: 330px) {
  .offcanvas .nav-link.english {
    text-align: left;
    font-size: 14px;
  }

  .offcanvas .logoImg {
    width: 60px;
  }

  .offcanvas .nav-link.arabic {
    font-size: 12px;

  }

  .offcanvas .nav-link.english {
    font-size: 12px;
  }
}



.profile-dropdown{
  background-color: white;
  padding: 4px;
  width:150px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  top:24px;
  left: 14px;
}

.li-profile{
  padding: 5px 10px;
  font-size:12px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.menu-icon {
  margin-right: 8px;
}

