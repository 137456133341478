.sidebar {
  width: 14%;
  position: fixed;
  top: 85px;
  bottom: 0.3rem;
  left: 12rem;
  padding: 1.5rem;
  border: 2px solid #e5e5e5;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filter-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-container h1 {
  margin: 0;
}
.filter-container .close-sidebar {
  display: none;
}

.filter-container p {
  margin: 0;
  cursor: pointer;
}

@media (max-width: 992px) {
  .sidebar {
    display: none;
  }
  .filter-container .close-sidebar {
    display: block;
  }
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .filter-container .close-sidebar {
    display: block;
  }
}

.responsive-sidebar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-top: 2px solid #e5e5e5;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 4;
}

.filter-icon {
  cursor: pointer;
}

