.price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  /* margin-bottom: 2rem; */
}
.price-container h2{
  color: #212121;
font-family: Rubik;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; 
}

.price-container input {
  margin-bottom: 5px;
  width: 100%;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.price-container button {
  width: 100%;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: #8139A1;
  display: flex;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
}
