.color-container {
  display: flex;
  flex-wrap: wrap;
  width: 180px;
}


.color-container h2 {
  color: #212121;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 140%;  */
}

.color-container .one {
  background-color: #A42A2A;
}

.color-container .two {
  background-color: #2F557B;
}

.color-container .three {
  background-color: #439ABB;
}

.color-container .four {
  background-color: #222222;
}

.color-container .five {
  background-color: #faf5f5;
  border: 2px solid black;
  
}

.color-container .six {
  background-color: #1ABA1A;
}

.color-container .seven {
  background-color: #696969;
}

.color-container .eight {
  background-color: #534898;
}

.color-container .nine {
  background-color: #FFE500;
}

.color-container .ten {
  background-color: #FF7A00;
}

.color-container .radio {
  display: none;
}

.color-container .label {
  padding: 2px 8px;
  font-size: 4px;
  margin: 5px;
  border: #212121;
}

.color-container .color-square {
  width: 22px;
  height: 20px;
  display: flex;
  cursor: pointer;
  border-radius: 2px;
}

.slider-btn .apply-btn {
  border-radius: 8px;
  background: #8139A1;
  color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: none;
  width: 100%;
}

.slider-btn .reset-btn {
  cursor: pointer;
}