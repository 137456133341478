@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.8) rotateY(-15deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotateY(0deg);
  }
}

.card-section {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  margin-top: 100px;
}

.card-section.animate-cards .card {
  opacity: 0;
  animation: popIn 0.6s ease-out forwards;
}

.card-section.animate-cards .card:nth-child(1) {
  animation-delay: 0.1s;
}
.card-section.animate-cards .card:nth-child(2) {
  animation-delay: 0.3s;
}
.card-section.animate-cards .card:nth-child(3) {
  animation-delay: 0.5s;
}

.card-section .card {
  background: #fff;
  border-radius: 12px;
  margin: 15px;
  padding: 20px;
  /* box-sizing: border-box; */
  text-align: center;
  transition: transform 0.4s ease, box-shadow 0.3s ease;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  transform-origin: center;
  perspective: 1000px;
  border: none;
}

/* 3D rotation and glowing shadow on hover */
.card-section .card:hover {
  transform: rotateY(5deg) rotateX(5deg) scale(1.02);
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 0 10px rgba(0, 123, 255, 0.3); */
}

.card-section .card img {
  width: 80px;
  height: auto;
  margin: 0 auto;
  transition: transform 0.4s ease;
}

.card-section .card:hover img {
  transform: scale(1.1);
}

.card-section .card h2 {
  color: #000;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 15px;
}

.card-section .card p {
  color: #959EAD;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

@media (max-width: 1024px) {
  .card-section .card {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .card-section .card {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .card-section .card h2 {
    font-size: 17px;
  }
}
