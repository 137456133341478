.rating-container {
    /* padding: 1rem; */
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    background-color: #fff;
  }
  
  .rating-container h2 {
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
  }
  
  .rating-checkboxes {
    display: flex;
    flex-direction: column;
    /* gap: 0.5rem; */
  }
  
  .rating-checkboxes label {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    color: #666;
  }
  
  .rating-checkboxes input[type="checkbox"] {
    /* margin-right: 0.5rem; */
  }
  