.favorites-container{
    margin-top: 100px;
    margin-bottom: 50px;
}
.all-favorite-items{
    display:grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .all-favorite-items{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .all-favorite-items{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 767px) {
    .all-favorite-items{
        grid-template-columns: repeat(1, 1fr);
    }
}

.empty-favorites {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
  }