.WhyChoose .boxs .box .imge::before{
    position: absolute;
    content: '';
    left: 20px;
    top: 10px;
    background-color: rgba(238, 77, 227, 0.301);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: -1;
}
/* 
.WhyChoose .rigth .img::before {
    content: '';
    position: absolute;
    left: -100px;
    top: -90px;
    background-image: url(../assets/dots-purple.svg);
    width: 255px;
    height: 217px;
    z-index: -1;
} */

/* .WhyChoose .rigth .img::before{
    content:'';
    position: absolute;
    left: -100px;
    top: -90px;
    background-image: url(../assets/camera.png);
    width: 255px;
    height: 217px;
    z-index: -1;
} */

.head-phone-img{
    width: 80%;
    height: 80vh;
    /* justify-content: flex-start; */
    margin-right: 0;
    margin-left: 30px;
}


.about_right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 768px) {
    .about_right{
        justify-content: center;
    }

    .head-phone-img{
        width: 100%;
        margin: auto;
    }
}