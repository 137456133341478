.chatbot-button {
    position: fixed;
    bottom: 70px;
    right: 20px;
    border-radius: 50%;
    z-index: 1000;
    cursor: pointer;
    width: 60px;
}

.chat-window {
    position: fixed;
    bottom: 150px;
    right: 20px;
    width: 320px;
    height: 400px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.chat-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #8139A1;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.chat-header .txt {
    margin-left: 10px;
}

.chat-body {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
}

.chat-message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    max-width: 80%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.chat-message.user {
    background-color: #8139A1;
    color: white;
    align-self: flex-end;
    border-bottom-right-radius: 0;
    margin-left: auto;
}

.chat-message.bot {
    background-color: #f1f1f1;
    align-self: flex-start;
    border-bottom-left-radius: 0;
    margin-right: auto;
}

.message-info {
    font-size: 12px;
    color: gray;
    /* align-self: flex-end; */
    /* margin-top: 5px; */
}

.chat-input {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ddd;
}

.chat-input input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 20px;
    margin-right: 10px;
}

.chat-input-icons {
    display: flex;
    gap: 10px;
    margin-right: 10px;
}

.chat-input button {
    padding: 10px 15px;
    border: none;
    background-color: #8139A1;
    color: white;
    border-radius: 20px;
    cursor: pointer;
}


.time {
    display: flex;
    /* align-items: center; */
    gap: 5px;
    margin: 5px auto;
}

.time-icon {
    margin-top: 4px;
}

.emoji-picker {
    position: absolute;
    bottom: 0;
    right: 20px;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.chatbot-question {
    text-decoration: underline;
    cursor: pointer;
    color: #8139A1;

}

/* .chat-message.user .message-info {
    align-self: flex-end;
    margin-left: auto;
  }
  
  .chat-message.bot .message-info {
    align-self: flex-start;
    margin-right: auto;
  } */
/* 
  .chat-message.user {
    background-color: #8139A1;
    color: white;
    align-self: flex-end;
    border-bottom-right-radius: 0;
    margin-right: auto;
}

.chat-message.bot {
    background-color: #f1f1f1;
    align-self: flex-start;
    border-bottom-left-radius: 0;
    margin-left: auto;
} */

/* .message-info {
    float: right;
  } */
/* .chat-message-container {
    display: flex;
    justify-content: space-between;
  }
  
  .message-info {
    margin-left: auto;
  } */
/* 
  .chat-message.user {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .chat-message.bot {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .message-info {
    margin-top: 5px; 
  }
  .chat-message.user .message-info {
    float: right;
    clear: both;
  }
  
  .chat-message.bot .message-info {
    float: left;
    clear: both;
  } */