.product-data {
    border-radius: 8px;
    margin: 1rem 0;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2rem 0;
  }
  
  .product-data__title h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #333;
  }
  
  .product-data__points {
    padding-left: 1rem;
  }
  
  .product-data__points h4 {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    color: #555;
  }
  
  .product-data__points p {
    margin: 0.25rem 0;
    font-size: 1rem;
    color: #666;
  }
  