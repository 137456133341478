
.form {
    width: 100%;
    /* max-width: 820px; */
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 120px;
    margin-bottom: 70px;
  }
  
  .contact-form {
    background-color: #8139A1;
    position: relative;
  }
  
  .circle {
    border-radius: 50%;
    background: linear-gradient(135deg, transparent 20%, #8139A1);
    position: absolute;
    /* margin-top: 50px; */
  }
  
  .circle.one {
    width: 130px;
    height: 130px;
    top: 130px;
    right: -40px;
  }
  
  .circle.two {
    width: 80px;
    height: 80px;
    top: 10px;
    right: 30px;
  }
  
  .contact-form:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #8139A1;
    transform: rotate(45deg);
    top: 50px;
    left: -13px;
  }
  
  .main-form {
    padding: 2.3rem 2.2rem;
    z-index: 10;
    overflow: hidden;
    position: relative;
  }
  
  .title {
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.7rem;
  }
  
  .input-container {
    position: relative;
    margin: 1rem 0;
  }
  
  .input {
    width: 100%;
    outline: none;
    border: 2px solid #fafafa;
    background: none;
    padding: 0.6rem 1.2rem;
    color: #fff;
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 25px;
    transition: 0.3s;
  }
  
  textarea.input {
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    border-radius: 22px;
    resize: none;
    overflow-y: auto;
  }
  
  .input-container label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #fafafa;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 1000;
    transition: 0.5s;
  }
  
  .input-container.textarea label {
    top: 1rem;
    transform: translateY(0);
  }
  
  .btn {
    padding: 0.6rem 1.3rem;
    background-color: #fff;
    border: 2px solid #fafafa;
    font-size: 0.95rem;
    color: #8139A1;
    line-height: 1;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
  }
  
  .btn:hover {
    background-color: transparent;
    color: #fff;
  }
  
  .input-container span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
  }
  
  .input-container span:before,
  .input-container span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    background-color: #8139A1;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .input-container span:before {
    left: 50%;
  }
  
  .input-container span:after {
    right: 50%;
  }
  
  .input-container.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
  }
  
  .input-container.focus span:before,
  .input-container.focus span:after {
    width: 50%;
    opacity: 1;
  }
  
  .contact-info {
    padding: 2.3rem 2.2rem;
    position: relative;
  }
  
  .contact-info .title {
    color: #8139A1;
  }
  
  .text {
    color: #333;
    margin: 1.5rem 0 2rem 0;
  }
  
  .information {
    display: flex;
    color: #555;
    margin: 0.7rem 0;
    align-items: center;
    font-size: 0.95rem;
  }
  
  .icon {
    width: 28px;
    margin-right: 0.7rem;
  }
  
  .social-media {
    padding: 2rem 0 0 0;
  }
  
  .social-media p {
    color: #333;
  }
  
  .social-icons {
    display: flex;
    margin: 0.5rem auto;
  }
  
  .social-icons a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(45deg, #8139A1, #8139A1);
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin-right: 0.5rem;
    transition: 0.3s;
  }
  
  .social-icons a:hover {
    transform: scale(1.05);
  }
  
  .contact-info:before {
    content: "";
    position: absolute;
    width: 110px;
    height: 100px;
    border: 22px solid #8139A1;
    border-radius: 50%;
    bottom: -77px;
    right: 50px;
    opacity: 0.3;
  }
  
  .big-circle {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #c898dd, #8139A1);
    bottom: 45%;
    right:52%;
    transform: translate(-40%, 38%);
  }
  
  .big-circle:after {
    content: "";
    position: absolute;
    width: 360px;
    height: 360px;
    background-color: #fafafa;
    border-radius: 50%;
    top: calc(50% - 180px);
    left: calc(50% - 180px);
  }
  
  .square {
    position: absolute;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(181%, 11%);
    opacity: 0.2;
  }
  
  @media (max-width: 850px) {
    .form {
      grid-template-columns: 1fr;
    }
  
    .contact-info:before {
      bottom: initial;
      top: -75px;
      right: 65px;
      transform: scale(0.95);
    }
  
    .contact-form:before {
      top: -13px;
      left: initial;
      right: 70px;
    }
  
    .square {
      transform: translate(140%, 43%);
      height: 350px;
    }
  
    .big-circle {
      bottom: 75%;
      transform: scale(0.9) translate(-40%, 30%);
      right: 50%;
    }
  
    .text {
      margin: 1rem 0 1.5rem 0;
    }
  
    .social-media {
      padding: 1.5rem 0 0 0;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 1.1rem;
    }
  
    .contact-info:before {
      display: none;
    }
  
    .square,
    .big-circle {
      display: none;
    }
  
    form,
    .contact-info {
      padding: 1.7rem 1.6rem;
    }
  
    .text,
    .information,
    .social-media p {
      font-size: 0.8rem;
    }
  
    .title {
      font-size: 1.15rem;
    }
  
    .social-icons a {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  
    .icon {
      width: 23px;
    }
  
    .input {
      padding: 0.45rem 1.2rem;
    }
  
    .btn {
      padding: 0.45rem 1.2rem;
    }
  }

/* 
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-card {
  background-color: white;
  border-radius: 14px;
  padding: 30px;
  text-align: center;
  width: 90%;
  max-width: 450px;
}

.popup-icon {
  width: 150px;
  margin: 0 auto;
}

.popup-message {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  text-align: center;
}

.popup-submessage {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
}

.popup-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #8139A1;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.popup-button:hover {
  background-color: #8139A1;
} */

.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #28a745;
  color: white;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  animation: fadeInOut 3s forwards;
}

.toast-icon {
  width: 40px;
  margin-right: 10px;
}

.toast-message {
  font-size: 1rem;
  margin: 0;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}
