
.reviews-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2rem;
    margin-top: 50px;
  }
  
  .reviews-column {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* grid-template-columns: repeat(1fr, 1fr); */
    gap: 2rem;
  }
  
  .review-card {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0 3rem 0 0; 
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    width: 40%;
    
  }
  @media (max-width: 768px) {
    .review-card{
      width: 100%;
      padding: 0;
    }
  }
  
  .review-card-top {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .user-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .user-img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
  }
  
  .user-info .stars {
    color: #ffd700;
    display: flex;
    
  }
  
  .review-card-center {
    margin: 1rem 0;
  }
  
  .review-card-bottom {
    display: flex;
    /* justify-content: space-around; */
    gap: 1rem;
  }
  
  @media (max-width: 768px) {
    .reviews-section {
      flex-direction: column;
    }
  
    .reviews-column {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  