.popup-notification {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    z-index: 1000;
}

.cards-section-container {
    margin-top: 100px;

}

.half-circle.top-left {
    top: 900px;
    left: 0;
    transform: translate(-50%, -50%);
}

.half-circle.bottom-right {
    bottom: 200px;
    right: 0;
    transform: translate(-50%, -50%) rotate(-90deg);
}

/* .half-circle.bottom-left {
    bottom: 0;
    left: 0;
    transform: translate(-300%, 50%) rotate(90deg);
  } */

  .cards-section-titles{
    margin-bottom: 25px;
  }

.cards-section-titles h2 {
    color: #161D25;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: 0.2px;
}

.cards-section-titles p {
    color: #959EAD;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.cards-container-section1 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    gap: 2rem;
}

.productCard-section1 {
    position: relative;
    background-color: #FFF;
    margin: 0.5rem;
    max-height: 500px;
    transition: 0.2s;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.productCard-section1 .productImage-container1 {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}


/* products cards in the home page */
.productCard-section1 .productImage-container1 .product-image1 {
    max-width: 80%;
    max-height: 80%;
    object-fit: cover;
    transition: all .2s ease-in-out;
}
.productCard-section1 .productImage-container1 .product-image1:hover {
    transform: scale(1.1);
}

.productCard-section1 .productImage-container1 .productCard__heart-wrapper1 {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: white;
    border-radius: 50%;
    padding: 0.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.productCard-section1 .productImage-container1 .productCard__heart1 {
    color: #C4CDD5;
    font-size: 1.5rem;
    cursor: pointer;
}


.productCard-section1 .productCard__content-section1 {
    text-align: left;
    width: 100%;
    flex-grow: 1;
}

.productCard__content-section1 .productImage1 {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.productCard__content-section1 .productName1 {
    color: #959EAD;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-bottom: 0.5rem;
}

.productCard__content-section1 .productType1 {
    color: #161D25;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    margin-bottom: 1rem;
}

.card-price1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.productPrice1 {
    color: #8139A1;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.productRating1 {
    display: flex;
    gap: 2px;
    color: #EEC200;
    font-size: 14px;
}

.productCard__buttons1 {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    margin-top: auto;
}

.productCard__button1 {
    padding: 1.5rem;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    font-size: 1rem;
    width: 100%;
    text-align: center;
    border-radius: 8px;
}

.productCard__button--purple1 {
    background-color: #8139A1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem
}

.productCard__button .filter-cart-icon1 {
    color: white;
    align-items: center;
    text-align: center;
    margin: 0 3px 0 0;
}

.productCard__button--gray1 {
    background-color: #C4CDD5;
    color: white;
}

@media (max-width: 768px) {
    .half-circle,
    .arrows-container,
    .custom-prev-arrow,
    .custom-next-arrow {
        display: none;
    }

}


@media (max-width: 480px) {
    .productCard__content-section1 .productType1 {
        font-size: 12px;
    }
    .productCard-section1 .productImage-container1 {
        height: 136px;
        margin-bottom: 0;
    }
}

@media (max-width: 426px) {
    .productCard__content-section1 .productType1 {
        font-size: 11px;
    }
    .productCard__button{
        font-size:15px;
    }
}
@media (max-width: 400px) {
 
    .productCard__button{
        font-size:14px;
    }
}

@media (max-width: 380px) {
    .productCard__content-section1 .productType1 {
        font-size: 18px;
    }
}



.star {
    color: #ffd700;
    font-size: 20px;
  }
  

  /* cart  in cartTap*/

div.scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #c4c4c4 transparent;
}

div.scrollbar::-webkit-scrollbar {
    width: 8px;
}

div.scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

div.scrollbar::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 10px;
}
