@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.slider-container .slider .slider__content .slider__img {
  width: 80%;
}

.slider-container .slider .slider__content .slider__img img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  margin: 10px auto;
}



/* ----------------- */


.slider-container {
  background-image: url("../assets/sliderBackground.png");
  background-size: cover;
  background-position: center;
  margin-top: 100px;
}

.slider-container .slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 450px;
  overflow: hidden;
}

.slider-container .slider .slider__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider-container .slider .slider__content .slider__img__main {
  width: 35%;
}

.slider-container  .slider .slider__content .slider__img__main img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.slider-container  .slider .slider__text {
  text-align: left;
  width: 50%;
  padding-left: 20px;
}

.slider-container  .slider .slider__text h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #FFF;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.slider-container  .slider .slider__text h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #FFF;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0.2px;
}

.slider-container  .slider .slider__text button {
  padding: 10px 20px;
  cursor: pointer;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  background-color: black;
  border-radius: 5px;
  display: flex;
  align-items: center; 
  justify-content: center; 
  border: none;
  margin-top: 35px;
  gap: 8px;
}

/* .slider__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  cursor: pointer;
  z-index: 10;
  color: black;
  background-color: #FFFFFF;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0;
}

.slider__arrow--left {
  left: 1rem;
}

.slider__arrow--right {
  right: 1rem;
} */


.underlined {
  position: relative;
  display: inline-block !important;
}

.underlined::before {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 0;
  height: 7px;
  width: 100% !important;
  border: solid 8px white;
  border-color: white transparent transparent transparent;
  border-radius: 50%;
}


@media (max-width: 992px) {
  .slider-container  .slider {
    height: 480px;
  }
}

@media (max-width: 768px) {
  .slider-container  .slider {
    height: 580px;
  }
  .slider-container  .slider .slider__content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }

  .slider-container  .slider .slider__text{
    width: 100%;
    padding: 0;
  }

  .slider__text h3 {
    font-size: .9rem;
    text-align: left;
    
  }

  .slider__text h1 {
    font-size: 2.5rem;
    letter-spacing: 0.1px;
    text-align: left;
  }

   /* .slider__img{
    width: 100%;
  }
.slider__img img {
    width: 100%;
    margin: 10px auto;
  } */

  .slider-container  .slider  button{
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    background-color: black;
    border-radius: 5px;
    display: flex;
    align-items: center; 
    justify-content: center; 
    border: none;
    width: 70%;
    margin-top: 30px;
  }

  /* .slider__content button {
    text-align: center;
    width: auto;
    margin-top: 10px;
  } */

  /* .slider__arrow{
    display: none;
    top: 50%;
    transform: translateY(-50%);
  } */
}

@media (max-width: 768px) {
  .slider-container .slider {
    height: 580px;
  }
  
  .slider-container .slider .slider__content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }

  .slider-container .slider .slider__img {
    width: 100%; 
    text-align: center;
  }

  .slider-container .slider .slider__img img {
    width: 70%;
    height: auto;
  }

  .slider-container .slider .slider__text {
    width: 100%;
    text-align: center; 
  }

  .slider-container .slider .slider__text h3, 
  .slider-container .slider .slider__text h1 {
    text-align: center;
  }

  .slider-container  .slider .slider__text h1 {
    font-size: 26px;
    margin: 15px 0;
  }

  /* .slider-container .slider button {
    margin: 20px auto; 
    width: 70%; 
  } */
}