.support-header {
    /* background-color: #f0f4f8;  */
    background-image: url('../../assets/header.png');
    background-size: cover;
    background-position: center;
    padding: 60px 0;
    padding: 150px 20px; 
    text-align: center;
  }
  
  .support-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    color: white; 
  }
  
  .search__container {
    position: relative;
    width: 100%;
    max-width: 500px; 
    margin: 0 auto;
  }
  
  .search-input {
    width: 100%;
    padding: 10px 20px 10px 45px;
    border-radius: 40px;
    border: 1px solid #ccc; 
    font-size: 16px;
  }

  .search-input:focus {
  outline: 1px solid #8139A1;
  }
  
  .search_icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #888; 
    font-size: 18px;
  }
  