.container-cards-filter {
  padding: 0;
  margin-top: 100px;
}

.container-cards-filter .filter-icon {
  display: none;
}

.container-cards-filter .filter-title {
  margin-left: 10em;
  margin-top: 15px;
  margin-bottom: 15px;
}

.total-items-filter {
  color: #B0B5B9;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  margin-left: 5rem;
}

.total-items-filter span {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
}

.card-container-filtration {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  /* gap: 2rem; */
  margin-left: 14rem;
}

.filter-productCard {
  position: relative;
  background-color: #FFF;
  margin: 0.5rem;
  max-height: 390px;
  transition: 0.2s;
  border-radius: 8px;
  box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.productCard__heart {
  position: absolute;
  top: 10px;
  right: 5px;
  color: #C4CDD5;
  font-size: 40px;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


.productImage-container {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 1rem; */
}

.productImage {
  max-width: 65%;
  max-height: 75%;
  object-fit: cover;
}

.productCard__content {
  text-align: left;
  width: 100%;
  flex-grow: 1;
}

.productName {
  color: #959EAD;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-bottom: 0.5rem;
}

.productType {
  color: #161D25;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  margin-bottom: 5px;
}

.card-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.productPrice {
  color: #8139A1;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.productRating {
  display: flex;
  gap: 2px;
  color: #EEC200;
  font-size: 14px;
}

.productCard__buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-top: auto;
}

.productCard__button {
  padding: 10px;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  font-size: 1rem;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.productCard__button--purple {
  background-color: #8139A1;
  color: white;
}

.productCard__button .filter-cart-icon {
  color: white;
  align-items: center;
  text-align: center;
  margin: 0 3px 0 0;
}

.productCard__button--gray {
  background-color: #C4CDD5;
  color: white;
}


/* .responsive-sidebar {
  display: none;
} */




.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
}

.pagination-arrow-icon {
  border: 1px solid black;
  padding: 10px 10px;
  border-radius: 4px;

}

.pagination-container .pagination-button {
  border: none;
  padding: 8px 16px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.pagination-container .pagination-button:hover,
.pagination-container .pagination-button:active {
  background-color: #8139A1;
  transition: background-color 0.3s ease;
  border: none;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  color: white;
}

/*  */

.container-cards-filter {
  padding: 0;
  margin-top: 70px;
  position: relative;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -70px;
  left: 60%;
  transform: translateX(-50%);
  width: 100%;
  padding: 10px;
  /* background-color: #f8f8f8; */
}

.pagination-arrow-icon,
.pagination-button {
  /* border: 1px solid #8139A1; */
  border: none;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  color: #8139A1;
}

.pagination-button.active {
  background-color: #8139A1;
  color: #fff;
}


.pagination-arrow-icon:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}


@media (max-width: 1090px) {
  .productImage {
    max-width: 45%;
    max-height: 65%;
  }
}

@media (max-width: 992px) {
  .card-container-filtration {
    margin: 0;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    /* margin-bottom: 8px; */
  }

  .total-items-filter{
    margin-left: 0;
  }


  .filter-productCard {
    width: 80%;
    margin: 5px auto;
    gap: 0;
  }

  .container-cards-filter {
    margin-left: 0;
    margin-top: 4rem;
  }

  .container-cards-filter .filter-title {
    margin-left: 0;
  }

  .container-cards-filter .filter-icon {
    display: block;
  }

  /* .show-sidebar {
    width: 100%;
  } */

  .pagination-container {
    left: 50%;
  }
}


@media (max-width: 768px) {
  .card-container-filtration {
    margin: 0;
  }

  /* .filter-productCard {
    width: 90%;
  } */

  .container-cards-filter {
    margin-left: 0;
    /* margin-top: 0; */
  }

  .container-cards-filter .filter-title {
    margin-left: 0;
  }

  .container-cards-filter .filter-icon {
    display: block;
  }

  /* .responsive-sidebar {
    height: 400px;
  } */

  .total-items-filter {
    margin: 5px 0;
  }
}

@media (max-width: 480px) {
  .card-container-filtration {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    gap: 3rem;
  }

  .filter-productCard {
    width: 90%;
  }
}



/*  */


/* Filter button container in mobile view */
.filter-container {
  display: none; /* Initially hidden, shown on mobile */
}

@media (max-width: 768px) {
  .filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f1f1f1;
    padding: 10px;
  }

  .toggle-button {
    font-size: 20px;
    cursor: pointer;
  }

  .close-sidebar-icon {
    font-size: 24px;
  }
}
