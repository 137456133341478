@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');

.footer {
    background-image: url('../../assets/header.png');
    padding-bottom: 10px;
    padding-top: 20px;
    /* margin-top: 50px; */
}


.footer-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px;

}


.second {
    padding: 0 50px;
}

.third {
    padding: 0 50px;
}


.footer-col {
    padding: 0 15px;
}

.footer-txt {
    color: #ffffff;
}


.footer-col h4 {
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    margin-top: 20px;
    /* margin-bottom: 20px; */
    font-weight: 500;
}

.footer-col .img-responsive {
    margin: 20px 0;
    width: 40%;
}

.footer-ul {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
}

.footer-col ul {
    list-style: none;
    padding: 0;
}

.footer-col ul li {
    margin-bottom: 10px;
}

.footer-col ul li a {
    font-size: 16px;
    color: #bbbbbb;
    text-decoration: none;
    transition: all 0.3s ease;
}

.footer-col ul li a:hover {
    color: #ffffff;
    padding-left: 1px;
}

.footer-col .social-links {
    display: flex;
    flex-wrap: wrap;
    transition: all 0.5s ease;
    text-decoration: none;
    color: #bbbbbb;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 117.687%;
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.footer-col .social-links .footer-icon:hover {
    color: #ffffff;
}



.styled-paragraph {
    color: white;
    text-align: center;
}

.footer-phone {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.footer-phone span {
    color: #bbbbbb;
    font-size: 16px;
}

.footer-phone span:hover {
    color: #ffffff;
}



.footer-link {
    text-decoration: none;
    font-size: 16px;
    color: #bbbbbb;
    transition: all 0.3s ease;
    cursor: pointer;
}

.footer-link:hover {
    color: #ffffff;
    /* padding-left: 2px; */
}



@media (max-width: 992px) {
    .footer-col {
        width: 50%;
        margin-bottom: 30px;
        text-align: center;
    }

    .footer-col ul,
    .footer-col .social-links {
        text-align: center;
        justify-content: center;
    }

    .footer-col .social-links {
        justify-content: center;
        margin-top: 10px;
        display: flex;
        align-items: center;
    }

    .footer-col .img-responsive {
        width: 28%;
    }

    .footer-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .footer-ul {
        /* display: flex; */
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .footer {
        padding: 0;
        margin: 0;
    }

    .footer-col {
        width: 50%;
        margin-bottom: 30px;
        text-align: center;
    }


    .footer-col h4 {
        margin-bottom: 15px;
    }

    .footer-col ul,
    .footer-col .social-links {
        text-align: center;
        justify-content: center;
    }

    .footer-col .social-links {
        justify-content: center;
        margin-top: 10px;
        display: flex;
        align-items: center;
    }
}

@media (max-width: 574px) {
    .footer-col {
        width: 100%;
    }
}


@media (max-width: 510px) {
    .styled-paragraph {
        font-size: 12px;
    }
}


@media (max-width: 410px) {
    .styled-paragraph {
        font-size: 12px;
    }

    .footer-col h4 {
        font-size: 14px;
    }
}