 /* Header.css */
.header-section {
  width: 100%;
  background-color: #f0f0f0;
  margin: 50px 0;
  font-family: 'Rubik', sans-serif;
  background-image: url('../../assets/header.png');
  background-size: cover;
  background-position: center;
  padding: 60px 0;
}

.header-container {
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: space-between;
  padding:100px 0;
}

.header-left {
  flex: 1;
  margin-right: 20px;
}

.header-left h1 {
  color: rgb(255, 255, 255);
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 72px;
  margin-bottom: 10px;
}

.header-left p {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  line-height: 26px;
  margin-bottom: 20px;
}

.header-button {
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}

.header-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.header-image {
  max-width: 450px;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 992px) {
  .header-container {
    flex-direction: column; 
    text-align: center;
  }

  .header-left {
    margin-right: 0;
  }

  .header-right {
    margin-top: 30px;
  }

  .header-image {
    max-width: 300px;
  }

  .header-left h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .header-left p {
    font-size: 14px;
    line-height: 20px;
  }

  .header-button {
    width: 160px;
  }
}

@media (max-width: 768px) {
  .header-left h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .header-left p {
    font-size: 12px;
    line-height: 18px;
  }

  .header-button {
    width: 140px;
    padding: 8px 16px;
  }

  .header-image {
    max-width: 250px;
  }
}
