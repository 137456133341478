.main_body{
  background-color: #f0f4f8;
  padding: 50px 0;
}

.support-content {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
  }
  
  .left-content, .right-content {
    flex: 1;
  }
  
  .left-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .title-border {
    width: 100%;
    height: 2px;
    background-color: #ddd; 
    margin-bottom: 20px;
  }
  
  .left-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px;
  }
  

  
  .left-cards .card {
    /* background-color: #f5f5f5; */
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    text-align: center;
    font-size: 18px;
  }
  
  .not_found{
    margin: 50px 0;
  }

  .not_found_inner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 18px;
    width: 70%;
    margin: 10px auto;
  }

  .not_found_inner .support_btn{
    background-color: #8139A1;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    .support-content  .left-cards{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  

  }